<!--
@Author: Calvin Green <calvin>
@Date:   2021-10-19T16:41:12+02:00
@Email:  calvin@diversitude.com
@Last modified by:   calvin
@Last modified time: 2021-11-05T19:40:18+02:00
@Copyright: Diversitude 2021
-->



<template>
<div>
    <b-overlay :show="isLoading">
        <b-row>
            <b-col cols="10">
                <b-card>
                    <b-row class="">
                        <b-col cols="12">
                            <div>
                                <div class="card-title" style="width: 100%;">
                                    <!-- <b-dropdown size="sm" v-show="readOnly == false || ownerEdit" style="float: right;" no-caret variant="outline-light" menu-class="documentMenu">
                                        <template #button-content>
                                            Actions &nbsp;<font-awesome-icon icon="fa-bars"/>
                                        </template>
                                        <b-dropdown-item @click="$emit('exitEditor')">Back</b-dropdown-item>
                                        <b-dropdown-item :disabled="was_data_changed == false" v-if="latestRevisionId == selected_revision_id" @click="saveChanges">Save New Draft</b-dropdown-item>
                                        <b-dropdown-item v-if="currentRevision != null && currentRevision.active == false && was_data_changed == false" @click="showPublishPrompt(currentRevision.id)">Approve and Publish</b-dropdown-item>
                                    </b-dropdown> -->
                                    
                                    <b-button title="User/Level Assignment" v-if="readOnly == false || ownerEdit" style="float: right;" size="sm" class="me-2" variant="primary" :disabled="hasRevisions == false" @click="showAssignmentModal">
                                        <font-awesome-icon icon="fa fa-user" />
                                    </b-button>
                                    <b-button title="File Manager" v-if="!readOnly || ownerEdit" style="float: right;" size="sm" class="me-2" variant="primary"  @click="openFileManModal()">
                                        <font-awesome-icon icon="fa fa-folder" />
                                    </b-button>
                                    <b-button title="Approve and Publish" :disabled="!(currentRevision != null && currentRevision.active == false && was_data_changed == false)" v-show="!readOnly || ownerEdit" class="me-2" size="sm" style="float: right" variant="success" @click="showPublishPrompt(currentRevision.id)">
                                        <font-awesome-icon :icon="['fas', 'check-double']" />
                                    </b-button>
                                    <b-button title="Save New Draft" :disabled="!(latestRevisionId == selected_revision_id)" v-show="!readOnly || ownerEdit" class="me-2" size="sm" style="float: right;" variant="success" @click="saveChanges">
                                        <font-awesome-icon icon="fa fa-floppy-disk" />
                                    </b-button>
                                    <b-button title="Copy Document Link" class="me-2" variant="info" size="sm" style="float: right;" @click="copyDocumentLink">
                                        <font-awesome-icon :icon="['fas', 'share-square']" />
                                    </b-button> 
                                    <b-badge v-if="saving && (!readOnly || ownerEdit)  && (latestRevisionId == selected_revision_id) && (currentRevision != null)" class="bg-success bg-soft font-size-12 text-dark d-flex" style="float: right; vertical-align: middle; margin-top: 0.1rem">
                                        <div style="display: inline-flex">
                                            <b-spinner small class="me-1"></b-spinner>
                                            <div style="margin-top: 0.2em">Saving...</div>
                                        </div>
                                    </b-badge>
                                    <!-- <h6 id="zoomLevel" class="m-1" > Zoom Level </h6> -->
                                    <b-button-group class="me-2" style="float: right; margin-left: 1rem">
                                        <b-button title="Zoom In" @click='increaseView' variant="outline-primary" size="sm">
                                        <font-awesome-icon :icon="['fas', 'search-plus']" />
                                        </b-button>
                                        <b-button title="Zoom Out"  @click='decreaseView' variant="outline-primary" size="sm"> 
                                            <font-awesome-icon :icon="['fas', 'search-minus']" />
                                        </b-button>
                                    </b-button-group>
                               
                                    <b-badge v-if="!saving && (!readOnly || ownerEdit) && (latestRevisionId == selected_revision_id) && (currentRevision != null)" class="bg-success bg-soft font-size-12 text-dark d-flex" style="float: right; vertical-align: middle; margin-top: 0.2rem">
                                        Saved
                                    </b-badge>
                                    <div v-if="document.type != 'template' && currentRevision == null" class="p-0 d-flex" style="float: right; vertical-align: middle;">
                                        <div class="m-1">Use Template:</div>
                                        <b-form-select  class="make-sm form-select mr-2" style="width: 200px; margin-right: 5px; min-height: 100%" size="sm"
                                        id="input-1"
                                        v-model="selected_template_type"
                                        placeholder="Select Template"
                                        :options="template_options"
                                        :disabled="template_options.length == 1"
                                        >
                                        </b-form-select>
                                        <b-button variant="outline-info" size="sm" v-if="template_options.length == 1" @click="handleAlert">
                                            <font-awesome-icon class="" icon="fa-solid fa-question-circle" />
                                        </b-button>
                                            
                                    </div>
                                    
                                    <span v-if="user != null" style="float: right; margin-right: 0.5rem;">
                                        <div style="display: inline-flex;">
                                            <span style="float: right; margin-right: 0.5rem;" v-show="isLoggedInUser(user.inbox_user_id) && user.status == 'sent' && (readOnly || !ownerEdit) && isUserOwnerOfDocument">
                                            New Revision Published: 
                                            </span>
                                            <b-button v-show="isLoggedInUser(user.inbox_user_id) && user.status == 'sent' && (readOnly || !ownerEdit) && isUserOwnerOfDocument" variant="success" size="sm" style="float: right; margin-right: 0.5rem;" @click="setStatus('accepted', user.id)">
                                                Accept
                                            </b-button>
                                            <b-button v-show="isLoggedInUser(user.inbox_user_id) && user.status == 'sent' && (readOnly || !ownerEdit) && isUserOwnerOfDocument" variant="danger" size="sm" style="float: right; margin-right: 0.5rem;" @click="setStatus('rejected', user.id)">
                                                Reject
                                            </b-button>
                                        </div>
                                    </span>
                                    <!-- <span>
                                        <b-button variant="success" size="sm" title="Link Document" style="float: right; margin-right: 0.5rem;" @click="showLinkDocumentModal()">
                                            <font-awesome-icon :icon="['fas', 'file-alt']" />
                                        </b-button>
                                    </span> -->
                                    <h6 class="">
                                        <b-button size="sm" @click="handleBack()" variant="outline-light">
                                            <font-awesome-icon icon="fa-arrow-left"/>
                                        </b-button>
                                        &nbsp; {{document.title}}
                                        <span class="text-muted" v-if="currentRevision != null">
                                            (Viewing draft no. {{currentRevision.draft_no}} of version {{currentRevision.version_number}})
                                        </span>
                                        <span id="docViewCount" class="text-muted" v-b-popover.hover="viewList" v-if="currentRevision != null && viewCount > 0">
                                            (Document View Count. {{ viewCount }})
                                        </span>
                                    </h6>
                                </div>
                            </div>
                        </b-col>
                    </b-row>

                    <b-row>
                        <b-col cols="col-12" class="alert-row">
                            <div class="d-flex">
                                <b-alert class="w-100 me-1 ms-1 alert" v-show="readOnly && !ownerEdit" style="display:flex; justify-content: space-between; align-items: center; height: 44px" variant="primary" show><b>Revision in read-only mode</b>
                                    <b-button v-show="readOnly && !ownerEdit && !blockEnableEdit && isUserOwnerOfDocument" size="sm" style="float: right;" variant="success" @click="allowEdit()">
                                        Enable Edit
                                    </b-button>
                                </b-alert>
                                <b-alert class="w-100 me-1 ms-1 alert" v-if="currentRevision && currentRevision.status == 'draft'" variant="warning" dismissible @dismissed="handleDismissDraft()" show><b>Revision in Draft</b></b-alert>
                                <b-alert class="w-100 me-1 ms-1 alert" v-if="isRevisionReadOnly && ownerEdit" variant="primary" dismissible @dismissed="handleDismissReadOnly()" show><b>Revision in read-only mode</b></b-alert> 
                            </div>
                        </b-col>
                    </b-row>  
                    <b-row>
                        <b-col cols="12">
                            <!--- <ckeditor v-if="readOnly && !(isRevisionReadOnly || !ownerEdit)" v-model="body" @input="onChange()" :read-only="isRevisionReadOnly || !ownerEdit" :config="editorConfig" @ready="onEditorReady"></ckeditor>
                            <b-card v-if="readOnly"><div class="p-3 border border-grey mainCardBody" v-show="readOnly && (isRevisionReadOnly || !ownerEdit)" style="user-select: none; min-height: 500px; overflow:scroll;" v-html="body"></div></b-card>
                            <ckeditor v-if="!readOnly" v-model="body" @input="onChange()" :read-only="!ownerEdit && isRevisionReadOnly" :config="editorConfig" @ready="onEditorReady"></ckeditor> -->                       
                            <ejs-documenteditorcontainer 
                                v-if="!readOnly" 
                                v-on:created="onCreated" 
                                v-on:contentChange="onChange" 
                                height="800px" 
                                width="100%" 
                                ref="container" 
                                :serviceUrl='serviceUrl'
                                :enableToolbar='true' 
                                :toolbarItems="toolBarItems"   
                                :showPropertiesPane='false'></ejs-documenteditorcontainer>
                            <ejs-documenteditor 
                                v-if="readOnly && !ownerEdit" 
                                v-on:created="onCreated" 
                                ref="documenteditor" 
                                :showPropertiesPane='false' 
                                :serviceUrl='serviceUrl' 
                                width="100%" 
                                :zoomFactor="scale"
                                :enableSelection='true' 
                                :isReadOnly='true' 
                                v-bind:requestNavigate="onRequestNavigate" 
                                height="800px"> </ejs-documenteditor>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
            <b-col cols="2">
                <!-- DOCUMENT DETAILS COLUMN -->
                <b-card no-body v-if="currentRevision != null" style="font-size: 0.7rem;">
                    <div v-b-toggle="'documentDetails'" class="d-flex bg-primary bg-soft ps-1 border border-grey" style="cursor: pointer;"  >
                        <strong style="font-size: 0.7rem;">
                            Details
                        </strong>
                        
                        <font-awesome-icon class="fa-fw fa-lg ms-auto" :icon="collapse_1 ? 'fa-minus' : 'fa-plus'"/>
                    </div>
                    <b-collapse v-model="collapse_1" :id="'documentDetails'" visible accordion="details-accordion" class="">
                        <div v-show="is_editing_details == false" class="p-1">
                            <!-- <font-awesome-icon v-show="isUserOwnerOfDocument" class="fa-fw edit-icon-right-aligned" icon="fa-pen" @click="editDocumentDetails()"/> -->
                            <div class="mb-2"><strong>Title: </strong>
                                {{document.title}}
                                <font-awesome-icon v-show="isUserOwnerOfDocument" class="fa-fw edit-icon-right-aligned" style="margin: 2px" icon="fa-pen" @click="editDocumentDetails()"/>
                            </div>
                            <div class="mb-2"><strong>Type: </strong>
                                {{document.type}}
                            </div>
                            <div class="mb-2"><strong>Status: </strong>
                                <span v-if="currentRevision.status != 'pending_approval'" class="text-capitalize">{{currentRevision.status}}</span>
                                <span v-if="currentRevision.status == 'pending_approval'">Pending Approval</span>
                            </div>
                            <div class="mb-2"><strong>Issue Date: </strong>
                                {{formatDate(currentRevision.issue_date)}}
                            </div>
                            <div class="mb-2"><strong>Owner: </strong>
                                <span v-if="!archivedUserCheck">{{currentRevision.author_name}}</span>
                                <span v-if="archivedUserCheck">Archived User ({{currentRevision.author_name}})</span>
                            </div>
                            <!--<div class="mb-2 acceptedIcon"><strong>Accepted: </strong>
                                <i class="fas" :class="userAcceptedIcon(currentRevision.user_accepted)"></i>
                            </div>-->                                                      
                            <div class="mb-2" v-if="isUserOwnerOfDocument">
                                <strong>Version: </strong>
                                
                                <div class="pt-1" style="border: 1px solid lightgrey;">
                                    <webix-ui v-if="version_revisions_data.length > 0" style="height: 100%;" :config="ui" v-model="version_revisions_data" ></webix-ui>
                                </div>
                                
                            </div>
                        </div>
                        <div v-if="is_editing_details" class="p-1">
                            <div class="span-right-aligned">
                                <font-awesome-icon class="fa-fw edit-save-icon" icon="fa-save" @click="updateDocument()"/>
                                <font-awesome-icon class="fa-fw edit-close-icon" icon="fa-times"  @click="closeEditDocument()"/>
                            </div>
                            <b-row>
                                <b-col cols="12">
                                    <label class="mb-0" for="">Title:</label>
                                    <b-form-input v-model="details_edit.title" size="sm" type="text"></b-form-input>
                                </b-col>
                                <b-col cols="12">
                                    <label class="mb-0" for="">Type:</label>
                                    <b-form-select v-model="details_edit.type" size="sm" class="make-sm form-select" :options="type_options"></b-form-select>
                                </b-col>
                                <!--<b-col cols="12" class="mt-2">
                                    <label class="mb-0" for="">Owner:</label>
                                    <b-form-input v-model="details_edit.title" size="sm" type="text"></b-form-input>
                                </b-col>-->
                                <b-col cols="12" class="mt-2">
                                    <label class="mb-0" for="">Structures:</label>
                                    <div>
                                        <font-awesome-icon class="fa-fw" icon="fa-pen" @click="showHierarchyModal()"/>
                                        <span>{{getLevelNameForIds(details_edit.level_ids)}}</span>
                                    </div>
                                    <!--<b-form-input v-model="details_edit.level_ids" size="sm" type="text"></b-form-input>-->
                                </b-col>
                            </b-row>
                        </div>
                    </b-collapse>


                    <div v-b-toggle="'purpose'" class="d-flex bg-success bg-soft border border-grey ps-1" style="cursor: pointer;">
                        <strong style="font-size: 0.7rem;">
                            Purpose
                        </strong>
                        <font-awesome-icon class="fa-fw fa-lg ms-auto" :icon="collapse_3 ? 'fa-minus' : 'fa-plus'"/>
                    </div>
                    <b-collapse v-model="collapse_3" :id="'purpose'" accordion="details-accordion">
                        <div class="p-1">
                            <div class="mb-2">
                                <div class="my-1 p-1" style="border: 1px solid lightgrey;">
                                    <p class="greyIcon" v-if="isUserOwnerOfDocument" @click="addPurposeModal()">Add Purpose</p>
                                    <p class="" v-if="!isUserOwnerOfDocument">No Purpose Added</p>
                                    <b-alert variant="light" class="m-0" v-if="document.purpose" :show="document.purpose.length == 0">No Purpose Added</b-alert>
                                    <div v-if="document.purpose" v-b-tooltip title="">
                                        <div class="mb-1">
                                            <div><strong>{{ document.purpose }}</strong></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </b-collapse>

                    <div v-b-toggle="'associatedDocuments'" class="d-flex bg-primary bg-soft border border-grey ps-1" style="cursor: pointer;">
                        <strong style="font-size: 0.7rem;">
                            Associated Documents
                        </strong>
                        <font-awesome-icon class="fa-fw fa-lg ms-auto" :icon="collapse_4 ? 'fa-minus' : 'fa-plus'"/>
                    </div>
                    <b-collapse v-model="collapse_4" :id="'associatedDocuments'" accordion="details-accordion">
                        <div class="p-1">
                            <linkedDocs @handleInsert="insertLink" :payload="document"/>
                        </div>
                    </b-collapse>

                    <div v-b-toggle="'coAuthors'" v-if="isUserOwnerOfDocument" class="d-flex bg-success bg-soft border border-grey ps-1" style="cursor: pointer;">
                        <strong style="font-size: 0.7rem;">
                            Co-Author 
                        </strong>
                        <font-awesome-icon class="fa-fw fa-lg ms-auto" :icon="collapse_5 ? 'fa-minus' : 'fa-plus'"/>
                    </div>
                    <b-collapse v-model="collapse_5" :id="'coAuthors'" accordion="details-accordion">
                        <div class="p-1">
                            <CoAuthors :payload="documentViewList" />
                        </div>
                    </b-collapse>
                    
                    <div v-b-toggle="'acceptanceDetails'" class="d-flex bg-primary bg-soft border border-grey ps-1" style="cursor: pointer;"
                        v-if="currentRevision"  >
                        <strong style="font-size: 0.7rem;">
                            Audience <span v-if="currentRevision.acceptance_tracking.length != 0">({{ getAcceptedAcceptanceCount }}/{{ getAcceptedAcceptanceCountLength }})</span>
                        </strong>
                        <font-awesome-icon class="fa-fw fa-lg ms-auto" :icon="collapse_2 ? 'fa-minus' : 'fa-plus'"/>
                    </div>
                    <b-collapse v-model="collapse_2" :id="'acceptanceDetails'" accordion="details-accordion"
                        v-show="currentRevision"
                    >
                        <b-alert show v-show="currentRevision.active == false" class="m-2">
                            Audience can only be viewed on the published revision.
                        </b-alert>
                        <b-alert show v-show="currentRevision.active && hasAssignments == false" class="m-2">
                            No users are assigned to this document.
                        </b-alert>
                        <AcceptanceUsers v-if="currentRevision.active && hasAssignments" class="p-1" :users="currentRevision.acceptance_tracking"/>
                    </b-collapse>
                </b-card>
                <b-card v-else>
                    <b-alert :show="true">Save document to view revision details</b-alert>
                    <b-button class="w-100" variant="success" @click="saveChanges()">
                        Save First Revision
                    </b-button>
                </b-card>
            </b-col>
        </b-row>
        <FileManagerModal v-if="show_filemanager" :document_id="document.id" :body_content="body" @insertImage="insertImage" @insertPdf="insertPdfLink" @removeMedia="removeMedia"></FileManagerModal>
        <purposeModal v-show="showPurposeModal"/>
        <AssignmentModal v-if="show_assignment_modal"/>
        <EditDocumentDetailsModal
        v-if="show_details_modal"
        @updatedDocumentDetails="handleDocumentDetailsUpdate"
        :edit_document="details_edit" />
        <HierarchyModal
            @updatedLevels="handleRowLevelsUpdate"
            :current_row="hierarch_modal_payload"
            v-if="show_hierarchy_modal"
        />
        <linkDocumentModal v-if="show_link_document_modal" :payload="document_modal_payload"/>
    </b-overlay>
</div>

</template>

<script>
import { format, parseISO } from 'date-fns'
import _ from 'lodash'
import { adminComputed, levelComputed, handbookMethods, handbookComputed } from "@/state/helpers";
import purposeModal from './purposeModal.vue'
import AcceptanceUsers from './acceptanceUsers.vue'
//local modals
import AssignmentModal from './modals/assignmentModal.vue'
import EditDocumentDetailsModal from './modals/editDocumentDetailsModal.vue'
import FileManagerModal from './modals/fileManagerModal.vue'
import linkDocumentModal from './modals/linkDocumentModal.vue'
import linkedDocs from './linkedDocs.vue'
//global modal
import HierarchyModal from '@/components/widgets/linkingModals/levels.vue'

import CoAuthors from './coAuthors.vue'

//eslint-disable-next-line
import { DocumentEditorContainerComponent, DocumentEditorComponent, Toolbar, RequestNavigateEventArgs, Selection } from '@syncfusion/ej2-vue-documenteditor';

export default {
    data:() => ({
        isLoading: true,
        body: '',
        selected_revision_id: null,
        selected_revision_index: null,
        editorLoaded: false,
        is_co_author: false,
        accepted: false,
        show_draft_alert: false,
        show_read_only_alert: false,
        show_enable_edit_alert: false,
        show_filemanager: false,
        dismissed_draft: false,
        dismissed_readOnly: false,
        revision_acceptance: null,
        editor: null,
        ownerEdit: false,
        scale: 1,
        removeImage: false,
        acceptance_tracking_total: 0,
        acceptance_tracking_count: 0,
        //version_revisions_data: [],
        //* configuration options for ckeditor4
        //editorConfig: ,

        serviceUrl: "https://docservice.diversitude.co.za/api/documenteditor/",
        toolBarItems: ['New', 'Open', 'Separator', 'Undo', 'Redo', 'Separator', 'Image', 'Table', 'Hyperlink', 'Bookmark', 'TableOfContents', 'Separator', 'Header', 'Footer', 'PageSetup', 'PageNumber', 'Break', 'InsertFootnote', 'InsertEndnote', 'Separator', 'Find', 'Separator', 'Comments', 'TrackChanges', 'LocalClipboard', 'Separator', 'FormFields', 'UpdateFields'],

        showPurposeModal: false,

        show_assignment_modal: false,
        show_link_document_modal: false,

        //expanded b-collapse element
        expanded_item: 'details',
        collapse_1: true,
        collapse_2: false,
        collapse_3: false,
        collapse_4: false,
        collapse_5: false,

        show_details_modal: false,
        details_edit:{},
        is_editing_details: false,
        allow_edit_data_change: false,

        show_hierarchy_modal: false,
        document_modal_payload: {},
        hierarch_modal_payload:{
            level_ids: [],
        },

        //* on change listener var
        was_data_changed: false,
        level_changed: false,
        viewCount: null,
        user_locked_doc: false,
        blockEnableEdit: false,
        stoploop: false,
        saving: false,
        documentViewList: [],
        documentEditList: [],
        template_options: [{value: 0, text: 'No Template'}],
        selected_template_type: 0,

        revisionData: {},
        time: 0, // Initialize time
        timeoutID: null, // Store timeout ID for managing the timer
    }),
    props:{
        readOnly:{
            required: false,
            default: true,
        }
    },
    watch:{
        document:{
            handler(newValue, oldValue){
                if(newValue.id != oldValue.id) {
                    this.$emit('updateReadOnly')
                    // this.ownerEdit = false
                    // this.read_only = true
                    this.$nextTick(() => {
                        this.initEditor()
                    })
                    
                }
            }
        },
        was_data_changed(newVal){
            this.setUnsavedChangesFlagState(newVal)
            console.log(newVal)
            if(newVal && this.currentRevision != null) {
                this.autoSaveTimer()
            } else {
                this.cleanupAutoSave()
            }
        },
         selected_template_type() {
                if(this.selected_template_type != 0){
                    if(this.was_data_changed){
                    this.$swal.fire({
                        title: "Changes Made",
                        text: "Setting a template will clear all current changes",
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#34c38f",
                        cancelButtonColor: "#f46a6a",
                        confirmButtonText: "Yes",
                    }).then((result) => {
                        if (result.value) {
                            //* If user selects yes
                            this.was_data_changed = false;
                            this.setTemplate()
                        }
                    });
                } else{
                    this.setTemplate()
                }  
            } else {
                console.log(this.initial_blob)
                this.$refs.container.ej2Instances.documentEditor.openBlank();
            }
        },
        body() {
            this.allow_edit_data_change = false
        },
        document_state() {
           if(!this.document_state.edit_locked) {
               this.blockEnableEdit = false
            } else {
                this.blockEnableEdit = true
            }
        },
        selected_revision_id:{
            //immediate: true,
            handler(newValue, oldValue){
                //this.body = _.cloneDeep(this.getActiveRevision())
                this.loadRevision(this.selected_revision_id);
                setTimeout(() => {
                    console.log('checking alerts')
                    this.show_draft_alert = false;
                    this.show_read_only_alert = false;
                    this.show_enable_edit_alert = false;
                    this.checkAlerts()
                    //this.resizeDoc()
                    this.updateDocumentEditorSize()
                },1000)
                if(this.selected_revision_id != null){
                    let mainTree = window.webix.$$('revisionsTree');
                    if(mainTree){
                        if(mainTree.exists(newValue)){
                            //mainTree.select(newValue);
                            mainTree.addCss(newValue, 'selected_style');
                        }
                        if(oldValue && mainTree.exists(oldValue)){
                            mainTree.removeCss(oldValue, 'selected_style')
                        }
                    }
                    
                }
            }
        },
        scale() {
            if(this.$refs.container) {
               this.$refs.container.ej2Instances.documentEditor.zoomFactor = this.scale;
            }
       },
    },
    provide: {
        //Inject require modules.
        DocumentEditorContainer: [Toolbar],
        DocumentEditor: [Selection]
    },
    components:{
        //ckeditor: CKEditor.component,
        purposeModal,
        AssignmentModal,
        EditDocumentDetailsModal,
        AcceptanceUsers,
        HierarchyModal,
        CoAuthors,
        //simplebar,
        FileManagerModal,
        linkDocumentModal,
        linkedDocs
    },
    computed:{
        ...levelComputed,
        ...handbookComputed,
        ...adminComputed,                    
        archivedUserCheck() {
            let archivedCheck = this.all_users.find(e => { 
                return e.id == this.currentRevision.author_user_id
            })
            console.log(archivedCheck)
            if(!archivedCheck) {
                return true
            } else {
                return false
            } 
        },
        formattedDate(date) {
            return format(parseISO(date), 'dd-MMMM-yyyy')
        },
        editorConfig() {
            let config = {
                contentsCss: [
                    '/css/ckeditor4.css',
                    //'https://cdn.ckeditor.com/4.19.1/standard-all/plugins/tableselection/styles/tableselection.css',
                ],
                imageUploadUrl: process.env.VUE_APP_API_BASEURL + "api/documents/uploadmedia",
                fontSize_defaultLabel: '10',
                link: {
                addTargetToExternalLinks: true
                },
                allowedContent: true,
                extraPlugins: 'dialogui,uploadimage,dialog,a11yhelp,basicstyles,bidi,blockquote,clipboard,button,tableresize,panelbutton,panel,floatpanel,colorbutton,colordialog,menu,contextmenu,dialogadvtab,div,elementspath,enterkey,entities,popup,filebrowser,find,fakeobjects,floatingspace,listblock,richcombo,font,format,forms,horizontalrule,htmlwriter,iframe,image,indent,indentblock,indentlist,justify,link,list,liststyle,magicline,maximize,newpage,pagebreak,pastefromword,pastetext,preview,removeformat,resize,save,menubutton,scayt,selectall,showblocks,showborders,smiley,sourcearea,specialchar,stylescombo,tab,table,tabletools,templates,toolbar,undo,wysiwygarea',
                removeButtons: 'Source,Save,Preview,Print,NewPage,Templates,SelectAll,Anchor,Flash,Smiley,PageBreak,Iframe,Language,CreateDiv,ShowBlocks,Checkbox,Radio,TextField,Textarea,Select,Button,HiddenField,About',
            }
            return config;
        },
        getAcceptedAcceptanceCount() {
            let acceptanceData = this.currentRevision.acceptance_tracking
            let filtered = _.uniqBy(acceptanceData, 'inbox_user_name')
            console.log(JSON.parse(JSON.stringify(filtered)))
            let list = []
            filtered.forEach(notification => {
                this.all_users.forEach(user => {
                    if(notification.inbox_user_id == user.id) {
                       list.push(notification)
                    }
                })
            })
            let count = 0
            if(list.length != 0) {
                list.forEach(item => {
                    if(item.status == 'accepted') {
                        count++
                    }
                })
            }
            return count
        },
        getAcceptedAcceptanceCountLength() {
            let acceptanceData = this.currentRevision.acceptance_tracking
            let filtered = _.uniqBy(acceptanceData, 'inbox_user_name')
            let list = []
            filtered.forEach(notification => {
                this.all_users.forEach(user => {
                    if(notification.inbox_user_id == user.id) {
                       list.push(notification)
                    }
                })
            })
            // let filtered = acceptanceData.filter((m) => m.inbox_user_id !== this.message.user_id);
            return list.length
        },
        type_options(){
            return [
                { value: 'policy', text: 'Policy' },
                { value: 'process', text: 'Process' },
                { value: 'procedure', text: 'Procedure' },
                { value: 'guidline', text: 'Guidline' }
            ]
        },
        isUserOwnerOfDocument(){
            let co_author_index = this.document.co_author_users.findIndex( user => {
                return Number(user.id) == Number(this.core.id);
            });
            let is_co_author = co_author_index != -1;
            let is_owner = Number(this.core.id) == Number(this.document.owner_user_id);
            return is_owner || is_co_author ;
        },
        hasRevisions(){
            return this.document.revisions.length > 0
        },
        hasAssignments(){
            if(this.documents) {
                let current_doc = this.documents.find( doc => doc.id == this.document.id);
                if(current_doc){
                    return current_doc.assignments.length > 0
                }
            }
            return false;       
        },
        isRevisionReadOnly(){
            if(this.hasRevisions == false){
                //* When no revisions are found, allow editor to edit
                return false;
            }
            else{
                //* check if the newest revision == selected revision
                console.log(this.latestRevisionId != this.selected_revision_id);
                return this.latestRevisionId != this.selected_revision_id
            }
            
        },
        currentRevision(){
            let latestVersionIndex = this.document.revisions.length - 1
            //let latestRevisionIndex = this.document.revisions[latestVersionIndex].revisions.length - 1
            if(this.document.revisions.length == 0){
                return null;
            }
            else if(this.selected_revision_id == null){
                let index = _.findIndex(this.document.revisions[latestVersionIndex].revisions, {active: true});
                //return this.document.revisions[latestVersionIndex].revisions[latestRevisionIndex]
                if(index != -1){
                    return this.document.revisions[latestVersionIndex].revisions[index];
                }
                else{
                    let value = null
                    let latestRevisionIndex = this.document.revisions[latestVersionIndex].revisions.length - 1
                    if(this.document.revisions[latestVersionIndex].revisions.length != 0) {
                        value = this.document.revisions[latestVersionIndex].revisions[latestRevisionIndex];
                    }
                    return value
                }
            }
            else{
                let foundIndex = -1
                if(this.document.revisions.length > 1) {
                    this.document.revisions.forEach((revision, idx) => {
                        console.log(idx)
                        let index = _.findIndex(this.document.revisions[idx].revisions, {id: this.selected_revision_id});
                        console.log(index)
                        if(index != -1) {
                            foundIndex = idx
                            return
                        }
                    })
                } else {
                    foundIndex = latestVersionIndex
                }
                let index = _.findIndex(this.document.revisions[foundIndex].revisions, {id: this.selected_revision_id});
                if(index != -1){
                    return this.document.revisions[foundIndex].revisions[index];
                }
                else{
                    return null;
                }
            }

        },
        latestRevisionId() {
            let documentList = _.orderBy(_.cloneDeep(this.document.revisions), ['version_no'],['asc'])
            let latestVersionIndex = documentList.length -1
            if(this.document.revisions.length != 0) {
                let latestRevisionIndex = documentList[latestVersionIndex].revisions.length - 1
                if(this.document.revisions.length > 0 && documentList[latestVersionIndex].revisions[latestRevisionIndex].id){
                    return documentList[latestVersionIndex].revisions[latestRevisionIndex].id
                } else {
                    return -1
                }
            } else {
                return -1;
            }
        },
        ui(){
            let el = this;
            //eslint-disable-next-line
            return {
                id: 'revisionsTree',
                css: "webixModal",
                view: 'tree',
                scroll: true,
                autoheight: true,
                height: 100,
                // tooltip:function(obj){
                //     let level = el.levels.find(e => {return e.id == obj.level_id})
                //     return level.name;
                // },
                //template:"{common.icon()} #version_no#",
                template: (obj, common) => {
                    if(obj.version_no || obj.version_no == 0) {
                        return common.icon(obj, common) + `Version ` + obj.version_no
                    } else {
                        if(obj.status == 'published') {
                            return `<span class="m-1"> Draft ` + obj.draft_no + `</span><span class="badge text-dark bg-success bg-soft hoverRevisionItem badge-primary activeRevisionItem">Published</span>`
                        } else if (obj.status == 'archived') {
                            return `<span class="m-1"> Draft ` + obj.draft_no + `</span><span class="badge text-dark bg-info bg-soft hoverRevisionItem badge-info activeRevisionItem">Archived</span>`
                        } else {
                            return `<span class="m-1"> Draft ` + obj.draft_no + `</span>`
                        }
                        
                    }
                },
                minHeight: 500,
                maxHeight: 700,
                on:{
                    //eslint-disable-next-line
                    onItemClick(id, val, node){
                        let selected_item = this.getItem(id);
                        console.log(selected_item)
                        if(selected_item.$parent != 0) {
                            console.log("setting here")
                            el.selected_revision_id = Number(id)
                        }
                    },
                }
            }
        },
        version_revisions_data() {
                let data = _.cloneDeep(this.document.revisions);
                let processedData = []
                console.log(data)
                if(data.length > 1) {
                    data.forEach( (item) => {
                        item.data = item.revisions
                        processedData.push(item)
                    })
                    return _.orderBy(processedData, ['version_no'],['desc'])
                } else {
                    data[0].data = data[0].revisions
                    return data
                }               
        },
        user(){
            // returns user object OR null if user not found
            if(this.currentRevision) {
                const filtered_users = _.uniqBy(this.currentRevision.acceptance_tracking, 'inbox_user_id');
            return filtered_users.find( item => {
                return item.inbox_user_id == this.core.id;
            })
            } else {
                return null
            }    
        },
        viewList() {
            let html = ''
            if(this.documentViewList.length > 0) {
                this.documentViewList.forEach(name => {
                    html += `<li class='listItemStyles'>${name.name}</li>`
                })
            }
            console.log(this.documentViewList)
            return {
                customClass: 'view-list-popover-class',
                placement: 'auto',
                title: 'View List',
                html: true,
                content: `<ul class='listStyles'>`+html+`</ul>`
            }
        },
    },
    methods:{
        ...handbookMethods,
        copyDocumentLink() {
            let link = window.location.href + '/' + this.document.fetch_token
            navigator.clipboard.writeText(link);
            //this.$refs.documenteditor.ej2Instances.selection.copy();
            this.$swal.fire({
                icon: 'info',
                title: 'Document Link Copied',
                toast: true,
                position: 'top',
                showConfirmButton: false,
                timer: 3500,
                timerProgressBar: true,
            })
        },
        autoSaveTimer() {     
            this.startTimer();
        },
        cleanupAutoSave() {
            // Clear the timer
            clearTimeout(this.timeoutID);
        },
        resetTimer() {
            clearTimeout(this.timeoutID);
            this.time = 0;
            this.startTimer();
        },
        startTimer() {
            const timer = () => {
                console.log(this.time)
                if (this.time > 1) {
                    this.triggerAutoSave()
                }
                else {
                    this.time++;
                    this.timeoutID = setTimeout(timer, 1000);
                }
            };
            timer();
        },
        triggerAutoSave() {
            this.saving = true
            this.$refs.container.ej2Instances.documentEditor.saveAsBlob('Sfdt').then((blob) => {
                let exportedDocument = blob;
                let formData = new FormData();
                formData.append('documentName', this.$refs.container.ej2Instances.documentEditor.documentName);
                formData.append('revision_id', this.selected_revision_id);
                formData.append('file', exportedDocument);
                this.autoSaveDocument(formData).then(response => {
                    console.log(response)
                    if (response.status === 200 || response.status === 304) {
                        setTimeout(()=>{
                            this.was_data_changed = false
                            this.saving = false
                        }, 500);
                    }
                }).catch(error => {
                    console.error('Error saving document:', error);
                }); 
            });
        },
        // triggerAutoSave() {
        //     setInterval(() => {
        //         if (this.was_data_changed) {
        //             this.saving = true
        //             this.$refs.container.ej2Instances.documentEditor.saveAsBlob('Sfdt').then((blob) => {
        //                 let exportedDocument = blob;
        //                 let formData = new FormData();
        //                 formData.append('documentName', this.$refs.container.ej2Instances.documentEditor.documentName);
        //                 formData.append('revision_id', this.selected_revision_id);
        //                 formData.append('file', exportedDocument);
        //                 window.axios.post('api/documents/save_revision', formData, {
        //                     headers: {
        //                         'Content-Type': 'multipart/form-data'
        //                     }
        //                 }).then(response => {
        //                     console.log(response)
        //                     if (response.status === 200 || response.status === 304) {
        //                         console.log('Saved successfully');
        //                         this.refreshCurrentDocument(response.data.revision_id)
        //                         .then(()=>{
        //                             this.selected_revision_id = response.data.revision_id;
        //                             //this.initflippedModal()
        //                             setTimeout(()=>{
        //                                 this.was_data_changed = false
        //                                 this.saving = false
        //                             }, 200);
        //                         })
        //                     }
        //                 }).catch(error => {
        //                     console.error('Error saving document:', error);
        //                 }); 
        //             });
        //         }
        //     }, 5000);
        // },
        loadRevision(revisionId) {
            this.loadRevisionData(revisionId).then(() => {
                let value = this.selected_revision_data
                setTimeout(() => {
                    if (this.readOnly) {
                        this.$refs.documenteditor.open(value);
                    } else {
                        this.$refs.container.ej2Instances.documentEditor.open(value);
                    }
                }, 100)
            })
            // window.axios.get('api/documents/sfdt_revision', { params : {revision_id: revisionId}})
            //     .then((response)=>{
            //         this.revisionData = response.data;
            //         setTimeout(() => {
            //             if (this.readOnly) {
            //                 this.$refs.documenteditor.open(this.revisionData);
            //             } else {
            //                 this.$refs.container.ej2Instances.documentEditor.open(this.revisionData);
            //             }
            //             //this.$refs.documenteditor.open(this.revisionData);
            //         }, 150);
            //     });
        },
        initflippedModal(){
            this.is_loading = true
            setTimeout(() => {
                let matches = []
                matches.push(this.selected_revision_id);
                if(matches.length > 0){
                    let tree = window.webix.$$('revisionsTree');
                        matches.forEach( match => {
                            if(tree && this.currentRevision != null && tree.exists(match)) {
                                let promises = [ this.loadDataFromParents(match) ];
                                Promise.all(promises)
                                .then(()=>{
                                    setTimeout(() => {
                                        tree.addCss(match, 'selected_style');
                                        this.openTreeNode(match);
                                    }, 800) 
                                    
                                })
                            } else {
                                this.is_loading = false
                            }
                        })
                } else {
                    this.is_loading = false
                }
            }, 1500)
        },
        loadDataFromParents(id_array) {
            console.log(id_array)
            let tree = window.webix.$$('revisionsTree');
            let data = []
            if(tree){
                let item = tree.getItem(id_array);
                while(item.$parent != 0){
                    data.push(item.$parent);
                    tree.open(item.$parent);
                    item = tree.getItem(item.$parent);  
                }
            }
        },
        openTreeNode(id_array){
            console.log(id_array)
            let tree = window.webix.$$('revisionsTree');
            if(tree){
                let item = tree.getItem(id_array);
                //tree.closeAll()
                while(item.$parent != 0){
                    tree.open(item.$parent);
                    item = tree.getItem(item.$parent);  
                }
                tree.showItem(id_array);
                this.is_loading = false
            }
        },
        setStatus(type, notification_id){
            this.is_saving = true;
            let payload = {
                status: type,
                inbox_id: notification_id,
            };
            this.$eventHub.$emit('updatedStatus', payload);
            //this.setUsers(type);
        },
        openFileManModal() {
            this.show_filemanager = true;
            this.$nextTick(()=>{
                this.$bvModal.show('fileManager');
                this.$root.$once('bv::modal::hidden',(event)=>{
                    if(event.type == 'hidden' && event.componentId == 'fileManager'){
                        this.show_filemanager = false;
                    }
                })
            });
        },
        insertImage(url) {
            const imgTag = `<img alt="Image" src="${url}" />`;
            this.editor.insertHtml(imgTag);
            this.editor.fire('change');
            this.was_data_changed = true
        },
        insertPdfLink(obj) {
            const { url, linkName } = obj;
            const anchorTag = `<a href="${url}" target="_blank">${linkName}</a>`;
            this.editor.insertHtml(anchorTag);
            this.editor.fire('change');
            this.was_data_changed = true
        },
        insertLink(data) {
   
            console.log(data)
            let url = window.location.href + '/' + data.id;
            let text = `Document: ` + data.name
            this.$refs.container.ej2Instances.documentEditor.editor.insertHyperlink(url,data.text,text);
            // console.log(data)
            //const { url, linkName } = obj;
            // let url = window.location.href + '/' + data.id;
            // const anchorTag = `<a href="${url}" target="_blank">${data.text}</a>`;
            // this.editor.insertHtml(anchorTag);
            // this.editor.fire('change');
            // this.was_data_changed = true
        },
        removeImagesBySrc(htmlStr, srcValue) {
            // Parse the HTML string into a DOM object
            const parser = new DOMParser();
            const doc = parser.parseFromString(htmlStr, 'text/html');
            console.log(doc)
            // Find the images by src
            const elements = doc.querySelectorAll(`img[src="${srcValue}"]`);
            console.log(elements)
            // Remove all matching elements
            elements.forEach((element) => {
                element.parentNode.removeChild(element);
            });
            // Serialize the DOM object back into a string
            return doc.body.innerHTML;
        },
        removeElementsByHref(htmlStr, hrefValue) {
            // Parse the HTML string into a DOM object
            const parser = new DOMParser();
            const doc = parser.parseFromString(htmlStr, 'text/html');
            // Find the elements by href
            const elements = doc.querySelectorAll(`[href="${hrefValue}"]`);
            // Remove all matching elements
            elements.forEach((element) => {
                element.parentNode.removeChild(element);
            });
            // Serialize the DOM object back into a string
            return doc.body.innerHTML;
        },
        removeMedia(obj) {
            if (obj.filetype == "image") {
                this.body = this.removeImagesBySrc(this.body, obj.url);
            } else {
                this.body = this.removeElementsByHref(this.body, obj.url);
            }
        },
        checkforBrokenImages() {
            const parser = new DOMParser();
            const doc = parser.parseFromString(this.body, 'text/html');
            // Find the images in doc
            const elements = doc.querySelectorAll(`img`);
            console.log(elements)
            //check the img srcs and hide broken images
            elements.forEach((element) => {
                //let check = false
                this.imgExists(element.src, this.check);
            });
        },
        check(value) {
            this.body = this.removeImagesBySrc(this.body, value);
        },
        imgExists(url, callback){
            var s = document.createElement("IMG");
            s.src = url
            s.onerror = function(){
                callback(url)
            }
        },
        handleAlert() {
            this.$swal.fire({
                title: "Using Templates",
                text: "Add templates by adding and publishing documents using the template type.",
                icon: "info",
                confirmButtonColor: "#34c38f",
                confirmButtonText: "Ok",
            })
        },
        handleBack(){
            if(this.was_data_changed){
                this.$swal.fire({
                    title: "Unsaved Changes Found",
                    text: "Continue without saving?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#34c38f",
                    cancelButtonColor: "#f46a6a",
                    confirmButtonText: "Yes",
                }).then((result) => {
                    if (result.value) {
                        //* If user selects yes
                        this.was_data_changed = false;
                        this.$emit('exitEditor')
                    }
                });
            }
            else{
                this.$emit('exitEditor')
            }
            
        },
        onChange(){
            //run autoSave
            if(this.currentRevision != null) {
                this.resetTimer()
            }
            if(this.isLoading == false && this.was_data_changed == false){
                console.log('changes made')
                if(this.allow_edit_data_change) {
                    this.was_data_changed = false;
                } else {
                    this.was_data_changed = true;
                }
                this.allow_edit_data_change = false
                
            }
        },
        onEditorReady(editor) {
            this.editor = editor;
            //this.isLoading = false;
            this.$nextTick(() => {
                let el = this
                let documentId = this.document.id;
                this.editor.on('fileUploadRequest', function(evt) {
                    let xhr = evt.data.fileLoader.xhr;
                    const AUTH_TOKEN = localStorage.getItem('x_token');
                    xhr.setRequestHeader( 'Authorization',  `Bearer ${AUTH_TOKEN}`);
                    evt.data.requestData.document_id = documentId;
                });
                this.editor.on('fileUploadResponse', function() {
                    el.editor.fire('change');
                    el.was_data_changed = true;
                });
            });
        },
        initEditor(){
            if(this.document.revisions.length > 0){
                this.setRevision();
                this.removeBrokenImages()
            }
        },
        removeBrokenImages() {
            //get images 

            //run image check and remove based on url
        },
        acceptRevision(){
            this.isLoading = true;

            let params = {
                revision_id: this.currentRevision.id,
                accepted: true,
            }
            this.acceptDocumentRevision(params)
            .then(()=>{
                this.loadDocuments(this.selected_level.id)
                .then(()=>{
                    this.loadDocument(this.document.id)
                    .then(()=>{
                        this.isLoading = false;
                    })
                    .catch(()=>{
                        this.isLoading = false;
                    })
                })
                .catch(()=>{
                    this.isLoading = false;
                })
            });
        },
        setRevision(){
            this.show_draft_alert = false;
            this.show_read_only_alert = false;
            this.show_enable_edit_alert = false;
            this.checkAlerts()
            if(this.readOnly && !this.ownerEdit && !this.isUserOwnerOfDocument) {
                console.log("getting in here")
                let documentList = _.orderBy(_.cloneDeep(this.document.revisions), ['version_no'],['asc'])
                let active_index = 0;
                let lastestIndex = documentList.length - 1
                let revisionsArray = documentList[lastestIndex].revisions

                revisionsArray.forEach( (item, index) => {
                    if(item.status == "published") {
                        active_index = index
                    }
                })
                if(active_index != -1){
                    this.selected_revision_id = documentList[lastestIndex].revisions[active_index].id;
                }
                else{
                    this.selected_revision_id = documentList[lastestIndex].revisions[active_index].id;
                }
            } else {
                this.selected_revision_id = this.latestRevisionId
            }
        },
        getActiveRevision(){
            let latestVersionIndex = this.document.revisions.length -1
            let foundIndex = null
            if(this.document.revisions.length > 1) {
                this.document.revisions.forEach((revision, idx) => {
                    console.log(idx)
                    let index = _.findIndex(this.document.revisions[idx].revisions, {id: this.selected_revision_id});
                    console.log(index)
                    if(index != -1) {
                        foundIndex = idx
                        return
                    }
                })
            } else {
                foundIndex = latestVersionIndex
            }
            let index = -1
            if(foundIndex != null) {
                index = _.findIndex(this.document.revisions[foundIndex].revisions, { id: this.selected_revision_id });
            }
            if(index != -1){
                //return '<h1> Helloo </h1>'
                return this.document.revisions[foundIndex].revisions[index].document_body;
            }
            else{
                return 'Add Body Text'
            }
        },
        setActiveRevision(revision_id){
            if(this.was_data_changed){
                this.$swal.fire({
                    title: "Unsaved Changes Found",
                    text: "Warning, changes to the current revision will be lost.",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#34c38f",
                    cancelButtonColor: "#f46a6a",
                    confirmButtonText: "Yes",
                }).then((result) => {
                    if (result.value) {
                        //* If user selects yes
                        this.was_data_changed = false;
                        this.selected_revision_id = revision_id;
                    }
                });
            }
            else{
                this.isLoading = true;
                this.selected_revision_id = revision_id;
                setTimeout(()=>{          
                    this.isLoading = false;
                }, 500)
            }
        },
        isActiveRevision(id){
            if(id == this.selected_revision_id){
                return 'activeRevisionItem';
            }
            else{
                return '';
            }
        },
        allowEdit(){
            this.isLoading = true
            this.allow_edit_data_change = true
            this.show_enable_edit_alert = false
            this.is_loading = true;
            this.ownerEdit = true;
            setTimeout(() => {
                this.checkAlerts();
                this.setRevision()
                this.initflippedModal()
                this.setUnsavedChangesFlagState(false);
                this.initDocumentEcho();
                this.$emit('updateReadOnly')
                this.loadRevision(this.selected_revision_id)
                if(!(this.isRevisionReadOnly || !this.ownerEdit) || !this.readOnly) {
                    this.$nextTick(() => {
                        this.updateDocumentEditorSize()
                        this.isLoading = false
                    })
                } else {
                    this.isLoading = false
                }
                this.editDocumentHeartbeat(this.document.id).then(() => {    
                    if(this.document_state.edit_locked == false) {
                        this.user_locked_doc = true
                        this.lockDocument(this.document.id).then(() => {
                            this.$nextTick(() => {
                                this.editDocumentHeartbeat(this.document.id)
                            })
                            this.runHeartbeat()
                        })
                    } else {
                        this.$nextTick(() => {
                            let html = ''
                            if(this.documentEditList.length > 0) {
                                this.documentEditList.forEach((name, idx) => {
                                    if(this.documentEditList.length - 1 == idx) {
                                        html += `${name}.`
                                    } else {
                                        html += `${name},`
                                    }
                                })
                            }
                        this.$swal.fire({
                        title: "Document being edited",
                        html: "This document is being edited by " + html,
                        icon: "warning",
                        confirmButtonColor: "#34c38f",
                        confirmButtonText: "Ok",
                        })
                        this.$emit('updateReadOnly')
                        
                        //this.ownerEdit = false
                        // this.read_only = true
                        this.$nextTick(() => {
                            this.allow_edit_data_change = false
                            this.show_enable_edit_alert = true
                            this.is_loading = false;
                            this.ownerEdit =false;
                            this.initEditor()
                            this.blockEnableEdit = true
                        })
                    })
                    }
                })
            }, 1000)
                
                
        },
        saveChanges(){
            let coAuthorEdit = false
            if(this.document.co_author_users.length > 0) {
                this.document.co_author_users.forEach(user => {
                    if(user.id == this.core.id) {
                        coAuthorEdit = true
                    }
                })
            }
            let ownerEdit = false
            if(this.core.id == this.document.owner_user_id) {
                ownerEdit = true
            }
            if(!coAuthorEdit && !ownerEdit && this.currentRevision == null){
                this.$swal.fire({
                    title: "Warning",
                    text: "Only the owner of co-author can edit the document",
                    icon: "warning",
                })
                return;
            }
            /*if(!this.body){
                this.$swal.fire({
                    title: "Warning",
                    text: "This document is empty. Please add content and try again.",
                    icon: "warning",
                })
                return;
            }*/    
            this.isLoading = true;
            
            this.$refs.container.ej2Instances.documentEditor.saveAsBlob('Sfdt').then((blob) => {
                let exportedDocument = blob;
                let formData = new FormData();
                formData.append('documentName', this.$refs.container.ej2Instances.documentEditor.documentName);
                formData.append('document_id', this.document.id);
                formData.append('file', exportedDocument);
                this.saveRevision(formData).then(response => {
                    if (response.status === 200 || response.status === 304) {
                        console.log('Saved successfully');
                        this.refreshCurrentDocument(response.data.revision_id)
                        .then(()=>{
                            this.selected_revision_id = response.data.revision_id;
                            this.initflippedModal()
                            setTimeout(()=>{
                                this.was_data_changed = false
                            }, 500);
                        })
                    }
                }).catch(error => {
                    console.error('Error saving document:', error);
                });
            });
            /*this.saveRevision(params)
            .then((new_id)=>{
                this.refreshCurrentDocument(new_id)
                .then(()=>{
                    this.selected_revision_id = new_id
                    this.initflippedModal()
                    setTimeout(()=>{
                        this.was_data_changed = false
                    }, 500)
                    //this.$nextTick(()=>{
                    //    this.was_data_changed = false;
                    //})
                    
                })
            })
            .catch(()=>{
                this.isLoading = false;
            })*/
        },
        showPublishPrompt(revision_id){
            this.loadDocuments(this.current_level_id)
            if(this.hasAssignments){
                this.publishRevision(revision_id);
            }
            else{
                // * prompt for no assigned users
                this.$swal.fire({
                    title: "No users have been assigned to this document",
                    text: "Continue with document publishing?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#34c38f",
                    cancelButtonColor: "#f46a6a",
                    confirmButtonText: "Yes",
                }).then((result) => {
                    if (result.value) {
                        //* If user selects yes
                        this.publishRevision(revision_id);
                    }
                });
            }
        },
        publishRevision(id){
            this.isLoading = true;
            this.publishDocument(id)
            .then(()=>{
                this.$swal.fire({
                    icon:'success',
                    title:'Draft has been published',
                    toast: true,
                    position: 'top',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                })
                this.refreshCurrentDocument(id)
                .then(()=>{
                    this.isLoading = false;
                    setTimeout(()=>{
                        this.initflippedModal()
                        this.was_data_changed = false
                    }, 500)
                    
                })
                .catch(()=>{
                    this.isLoading = false;
                })
                
            })
            .catch(()=>{
                this.isLoading = false;
            })
        },
        refreshCurrentDocument(new_id = null){
            console.log("refreshing doc")
            this.is_loading = true;
            return new Promise( (resolve, reject) => {
                this.loadDocuments(this.selected_level.id)
                .then(()=>{
                    if(!(this.isRevisionReadOnly || !this.ownerEdit) || !this.readOnly) {
                        this.updateDocumentEditorSize()
                    } else {
                        this.isLoading = false
                    }
                    this.loadDocument(this.document.id)
                    .then(()=>{
                        if(new_id != null){
                            this.selected_revision_id = new_id;
                        }
                        this.$nextTick(()=>{
                            if(this.editor) {
                                this.editor.setData(this.getActiveRevision());
                            }
                            this.$nextTick(()=>{
                                
                                resolve();
                            })
                            
                        })
                    })
                    .catch(()=>{
                        this.isLoading = false;
                        reject();
                    })
                })
                .catch(()=>{
                    this.isLoading = false;
                    reject();
                })
            })
        },
        // addCommentModal(){
        //     this.showPurposeModal = true;
        //     this.$nextTick(()=>{
        //         this.$bvModal.show('commentModal');
        //         this.$root.$once('bv::modal::hidden',(event)=>{
        //             if(event.type == 'hidden' && event.componentId == 'commentModal'){
        //                 this.showPurposeModal = false;
        //             }
        //         })
        //     });
        // },
        addPurposeModal(){
            this.showPurposeModal = true;
            this.$nextTick(()=>{
                this.$bvModal.show('purposeModal');
                this.$root.$once('bv::modal::hidden',(event)=>{
                    if(event.type == 'hidden' && event.componentId == 'purposeModal'){
                        this.showPurposeModal = false;
                    }
                })
            });
        },
        isLoggedInUser(user_id){
            return this.core.id == user_id;
        },
        userAcceptedIcon(status){
            if(status){
                return 'fa-check'
            }
            else{
                return 'fa-times'
            }
        },
        levelUpdatedEvent(){
            this.$emit('exitEditor')
        },
        formatDate(date){
            let new_date = "";
            if(date != null){
                new_date = format(parseISO(date), 'dd/MM/yyyy');
            }
            return new_date;
        },
        showAssignmentModal(){
            this.show_assignment_modal = true;
            this.$nextTick(()=>{
                this.$bvModal.show('assignmentModal');
                this.$root.$once("bv::modal::hidden", (event) => {
                    if (event.type == "hidden" && event.componentId == "assignmentModal") {
                        this.show_assignment_modal = false;
                    }
                });
            })
        },
        debugAssignment(){
            let payload = {
                document_id: 28,
                user_ids:[],
                level_ids:[54, 55]
            }
            this.saveDocumentAssignments(payload);
        },
        handleInboxUpdate(){
            this.refreshCurrentDocument(this.currentRevision.id)
            .then(()=>{
                setTimeout(()=>{
                    this.was_data_changed = false
                }, 500)
            })
        },
        editDocumentDetails(){
            let doc = _.find(this.documents, { id: this.document.id});
            this.details_edit = _.cloneDeep(doc);
            this.show_details_modal = true;
            this.$nextTick(()=>{
                this.$bvModal.show('documentDetailsModal');
                this.$root.$once("bv::modal::hidden", (event) => {
                    if (event.type == "hidden" && event.componentId == "documentDetailsModal") {
                        this.show_details_modal = false;
                    }
                });
            })
        },
        closeEditDocument(){
            this.is_editing_details = false;
        },
        updateDocument(){
            let doc = _.find(this.documents, { id: this.document.id});
            //* save document as an array
            let updated_document = {
                id: doc.id,
                title: this.details_edit.title,
                level_ids: this.details_edit.level_ids,
                type: this.details_edit.type,
                owner_user_id: doc.owner_user_id,
            };
            let payload = {
                documents: [updated_document],
            }
            this.saveDocument(payload)
            .then(()=>{
                this.isLoading = true;
                this.refreshCurrentDocument(this.currentRevision.id)
                .then(()=>{
                    this.closeEditDocument();
                    this.$swal.fire({
                        icon:'success',
                        title:'Updated document details',
                        toast: true,
                        position: 'top',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                    this.was_data_changed = false;
                })
                //
            })
        },
        getLevelNameForIds(id_array){
            let names = '';
            id_array.forEach((id, idx) => {
                let lvl = _.find(this.levels, {id: id});
                if(lvl && idx == 0){
                    names += lvl.name;
                }
                else if(lvl && idx > 0){
                    names += `, ${lvl.name}`;
                }
            });
            return names;
        },
        showHierarchyModal(){
            let doc = _.find(this.documents, { id: this.document.id});
            this.hierarch_modal_payload.level_ids = doc.level_ids;
            this.show_hierarchy_modal = true;
            this.$nextTick(()=>{
                this.$bvModal.show('levelLinkingModal');
                this.$root.$once("bv::modal::hidden", (event) => {
                    if (event.type == "hidden" && event.componentId == "levelLinkingModal") {
                        this.show_hierarchy_modal = false;
                    }
                });
            })
        },
        onRequestNavigate(args) {
            console.log(args)
            if (args.linkType !== 'Bookmark') {
            let link = args.navigationLink;
            if (args.localReference.length > 0) {
                link += '#' + args.localReference;
            }
            window.open(link);
            args.isHandled = true;
        }},
        handleRowLevelsUpdate(payload){
            this.details_edit.level_ids = payload;
            this.$bvModal.hide('levelLinkingModal');
        },
        handleDocumentDetailsUpdate(payload){
            this.isLoading = true;
            let updated_document = {
                id: this.document.id,
                title: payload.title,
                level_ids: payload.level_ids,
                type: payload.type,
                owner_user_id: payload.owner_user_id,
                review_date: payload.review_date
            };
            let save_payload = {
                documents: [updated_document],
            }
            this.saveDocument(save_payload)
            .then(()=>{
                this.$bvModal.hide('documentDetailsModal');
                this.refreshCurrentDocument(this.currentRevision.id)
                .then(()=>{
                    this.closeEditDocument();
                    this.$swal.fire({
                        icon:'success',
                        title:'Updated document details',
                        toast: true,
                        position: 'top',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                    this.was_data_changed = false;
                })
            })
        },
        handleClearChanges(){
            this.was_data_changed = false;
        },
        handleDismissReadOnly() {
            this.show_read_only_alert = false
            this.dismissed_readOnly = true
            this.$nextTick(() => {
                this.updateDocumentEditorSize()
            })
        },
        handleDismissDraft() {
            this.show_draft_alert = false;
            this.dismissed_draft = true
            this.$nextTick(() => {
                this.updateDocumentEditorSize()
            })
        },
        checkAlerts() {
            if(this.isRevisionReadOnly && this.ownerEdit && !this.dismissed_readOnly) {
                this.show_read_only_alert = true
            }
            if(this.currentRevision && this.currentRevision.status == 'draft' && !this.dismissed_draft) {
                this.show_draft_alert = true
            }
            if(this.readOnly && !this.ownerEdit && (this.core.id == this.document.owner_user_id)) {
                this.show_enable_edit_alert = true
            }
        },
        showLinkDocumentModal(){
            this.document_modal_payload = {document: this.document}
            this.$nextTick(()=>{
                this.show_link_document_modal = true;
                this.$nextTick(()=>{
                    this.$bvModal.show('linkDocumentModal');
                    this.$root.$once("bv::modal::hidden", (event) => {
                        if (event.type == "hidden" && event.componentId == "linkDocumentModal") {
                            this.show_link_document_modal = false;
                        }
                    });
                })
            })
        },
        // resizeDoc() {
        //     console.log('running resize')
        //     let item = document.getElementsByClassName('cke_contents');
        //     console.log(item)
        //     let top = document.getElementsByClassName('cke_top');
        //     console.log(top)
        //     let value = document.getElementsByClassName('handbook');
        //     console.log(value)

        //     let body = document.getElementsByClassName('mainCardBody');
        //     let topHeight = 0;
        //     let alertHeight = 0;

        //     console.log(this.show_read_only_alert);
        //     console.log(this.show_draft_alert);
        //     console.log(this.show_enable_edit_alert);
            
        //     if((this.show_read_only_alert || this.show_draft_alert || this.show_enable_edit_alert)) {
        //         alertHeight = 60;
        //     }
        //     console.log(JSON.parse(JSON.stringify(this.document)));
        //     if(top.length > 0 && top[0].offsetHeight > 0) {
        //         topHeight = top[0].offsetHeight;
        //     }
            
        //     let height = window.innerHeight - 175 - topHeight - alertHeight;
        //     console.log(height);

        //     if(height < 300) {
        //         console.log('set here');
        //         if(item.length > 0) {
        //             item[0].style.height = '320px';
        //         }
        //         if(value.length > 0) {
        //             value[0].style.marginBottom = '70px';
        //         }
        //     } else {
        //         if(item.length > 0) {
        //             item[0].style.height = height + 'px';
        //         } else if(body.length > 0) {
        //             body[0].style.height = height + 'px';
        //         }
        //         if(value.length > 0) {
        //             value[0].style.marginBottom = '8px';
        //         }
        //     }

        //     this.$nextTick(() => {
        //         this.isLoading = false;
        //     });
        // },
        initDocumentEcho() {
            this.$echoManager.subscribeToPresenceChannel('doc-channel.' + this.document.id, {
                onHere: (usersList) => {
                    console.log('Users currently on the doc:', JSON.parse(JSON.stringify(usersList)));
                            //let users = [...new Set(usersList)];
                            let users = usersList.filter((value, index, self) =>
                                index === self.findIndex((t) => (
                                    t.id === value.id
                                ))
                            )
                            console.log(users)
                            if(users.length > 0) {
                            this.viewCount = users.length
                            let usersList = []
                            let allNames = []
                            users.forEach((user) => {
                                if(!this.isLoggedInUser(user.id)) {
                                    usersList.push(user.name)
                                }
                                allNames.push(user)
                            })
                            this.documentViewList = allNames
                            console.log(JSON.parse(JSON.stringify(usersList)))
                            this.documentEditList = usersList
                            if(usersList.length > 0 && this.document_state.edit_locked && !this.user_locked_doc) {
                                let html = ''
                                if(this.documentEditList.length > 0) {
                                    this.documentEditList.forEach((name, idx) => {
                                        if(this.documentEditList.length - 1 == idx) {
                                            html += `${name}.`
                                        } else {
                                            html += `${name},`
                                        }
                                    })
                                }
                                this.$swal.fire({
                                    title: "Document being edited",
                                    text: "This document is being edited by " + html ,
                                    icon: "warning",
                                    confirmButtonColor: "#34c38f",
                                    confirmButtonText: "Ok",
                                })
                                this.$emit('updateReadOnly')
                                // this.ownerEdit = false
                                // this.read_only = true
                                this.$nextTick(() => {
                                    this.initEditor()
                                    this.blockEnableEdit = true
                                })
                            }
                        } 
                },
                onJoining: (users) => {
                    this.editDocumentHeartbeat(this.document.id)
                    if(users.length == undefined && this.readOnly) {
                        this.unlockDocument(this.document.id)
                    }
                },
                onLeaving: () => {
                    this.editDocumentHeartbeat(this.document.id)
                },
                onEvent: {
                    'DocumentUpdateEvent': () => {
                        if(this.readOnly && !this.ownerEdit) {
                            this.is_loading = true
                            this.$swal.fire({
                                title: "Document Updated",
                                text: "A new draft is available. Would you like to view it or continue viewing the current draft?",
                                icon: "warning",
                                confirmButtonColor: "#34c38f",
                                confirmButtonText: "Ok",
                                showCancelButton: true,
                                cancelButtonColor: "#f46a6a",
                            }).then( result =>{
                                if(result.value){
                                    this.loadDocument(this.document.id)
                                    .then(()=>{
                                        this.initEditor()
                                        this.isLoading = false;
                                    })
                                } else {
                                    this.is_loading = false
                                }
                            })
                            
                        }  
                    },
                    'DocumentEditEvent': (data) => {
                        console.log('Edit flag set:', data);
                    },
                }
            });            
        },
        runHeartbeat() {
            setTimeout(() => {
                if(this.document) {
                    this.editDocumentHeartbeat(this.document.id)
                    if(!this.document_state.edit_locked) {
                        this.blockEnableEdit = false
                    }
                    if(!this.stoploop) {
                        this.runHeartbeat()
                    }
                }
            }, 10000)
        },
        unlockDocumentOnClose() {
            if(this.user_locked_doc) {
                this.unlockDocument(this.document.id)
            }
        },
        onWindowResize() {
        //Resizes the document editor component to fit full browser window automatically whenever the browser resized.
            this.updateDocumentEditorSize();
        },
        updateDocumentEditorSize() {
            //let height = window.innerHeight - 175
            let alertHeight = 0
            this.checkAlerts()
            if(this.show_read_only_alert || this.show_draft_alert || this.show_enable_edit_alert) {
                alertHeight = 60;
            }
            let height = window.innerHeight - 145 - alertHeight;
            //let width = window.innerWidth;
            //this.$refs.container.ej2Instances.resize(null, height);
            console.log(height)
            console.log(alertHeight)
            //console.log(width)
            let percentage = '100%'
            console.log(this.readOnly)
            if (this.readOnly) {
                if(this.$refs.documenteditor) {
                    this.$refs.documenteditor.resize(percentage, height); 
                }
            } else {
                if(this.$refs.container) {
                    this.$refs.container.ej2Instances.resize(percentage, height);
                }
                 
            }
            this.$nextTick(() => {
                this.isLoading = false
            })
        },
        onCreated: function () {
            this.updateDocumentEditorSize();
            window.addEventListener('resize', this.onWindowResize.bind(this));
            // if(this.$refs.container) {
            //     this.$refs.container.ej2Instances.documentEditor.selection.characterFormat.fontFamily= 'Arial';
            // }
            if(this.$refs.container) {
                this.$refs.container.ej2Instances.documentEditor.setDefaultCharacterFormat({fontFamily: 'Arial'});
            }
        },
        addTemplateOptions() {
            this.documents.forEach(doc => {
                if ((doc.is_owner || doc.is_co_author) && doc.published_version_no != "N/A" && this.document.id != doc.id && doc.type == "template") {
                    this.template_options.push({value: doc.id, text: doc.title})
                }

            })
        },
        setTemplate() {
            // get document by id
            window.axios.get(`api/documents/document/`, { params: { document_id: this.selected_template_type }})
            .then((response) => {
                // find the latest revision id
                console.log(response)
                let doc_data = response.data
                let documentList = _.orderBy(_.cloneDeep(doc_data.revisions), ['version_no'],['asc'])
                console.log(documentList)
                let latestVersionIndex = documentList.length - 1
                let docsLatestRevisionId = -1
                if(doc_data.revisions.length != 0) {
                     let latestRevisionIndex = documentList[latestVersionIndex].revisions.length - 1
                    if(doc_data.revisions.length > 0 && documentList[latestVersionIndex].revisions[latestRevisionIndex].id){
                        docsLatestRevisionId = documentList[latestVersionIndex].revisions[latestRevisionIndex].id
                    }
                }
                console.log(docsLatestRevisionId)
                if(docsLatestRevisionId != -1) {
                    this.loadRevisionData(docsLatestRevisionId).then(() => {
                        let value = this.selected_revision_data
                        setTimeout(() => {
                            if (this.readOnly) {
                                this.$refs.documenteditor.open(value);
                            } else {
                                this.$refs.container.ej2Instances.documentEditor.open(value);
                            }
                        }, 100)
                    })
                }
            })
        },
        onToolbarClick(args) {
            switch (args.item.id) {
                case 'Custom':
                //Disable image toolbar item.
                this.$refs.container.ej2Instances.toolbar.enableItems(4, false);
                break;
            }
        },
        increaseView() {
            let value = this.scale
            this.scale = value + 0.1
        },
        decreaseView() {
            if(this.scale > 0.2) {
                let value = this.scale
                this.scale = value - 0.1
            }
        },
    },
    mounted(){
        this.is_loading = true
        this.blockEnableEdit = true
        this.scale = 1
        //* event fired from router index.js for unsaved changes
        this.$eventHub.$on('clearDocumentChanges', this.handleClearChanges);
        this.$eventHub.$on('newLevelSelected', this.levelUpdatedEvent);
        this.$eventHub.$on('inboxUpdated', this.handleInboxUpdate);
        this.initEditor();
        this.checkAlerts() 
        
        //let el = this;
        setTimeout(() => {
            this.checkforBrokenImages()  
            this.initDocumentEcho(); 
            if(!this.readOnly) {
                
                this.editDocumentHeartbeat(this.document.id).then(() => {
                    if(this.document_state.edit_locked == false) {
                        this.user_locked_doc = true
                        this.lockDocument(this.document.id).then(() => {
                            this.editDocumentHeartbeat(this.document.id)
                        })
                    }
                })
                
            }
            this.runHeartbeat()
            this.initflippedModal()
            if(this.currentRevision == null) {
                this.addTemplateOptions()
            }
        },800)
    },
    beforeDestroy(){
        this.$eventHub.$off('clearDocumentChanges');
        this.$eventHub.$off('newLevelSelected');
        this.$eventHub.$off('inboxUpdated');
        this.cleanupAutoSave()
        //window.removeEventListener("beforeunload", this.handleClose);
        window.webix.eventRemove(this.webix_resize_event);
        this.$echoManager.leaveChannel('doc-channel.' + this.document.id)
        if(this.user_locked_doc) {
            this.unlockDocument(this.document.id)
        }
        //this.unlockDocument(this.document.id)
        this.setUnsavedChangesFlagState(false);
        this.editDocumentHeartbeat(this.document.id)
        this.stoploop = true
    }
}
</script>

<style>
@import '../../../node_modules/@syncfusion/ej2-base/styles/bootstrap5.css';
@import '../../../node_modules/@syncfusion/ej2-base/styles/bootstrap5.css';
@import '../../../node_modules/@syncfusion/ej2-buttons/styles/bootstrap5.css';
@import '../../../node_modules/@syncfusion/ej2-inputs/styles/bootstrap5.css';
@import '../../../node_modules/@syncfusion/ej2-popups/styles/bootstrap5.css';
@import '../../../node_modules/@syncfusion/ej2-lists/styles/bootstrap5.css';
@import '../../../node_modules/@syncfusion/ej2-navigations/styles/bootstrap5.css';
@import '../../../node_modules/@syncfusion/ej2-splitbuttons/styles/bootstrap5.css';
@import '../../../node_modules/@syncfusion/ej2-dropdowns/styles/bootstrap5.css';
@import "../../../node_modules/@syncfusion/ej2-documenteditor/styles/bootstrap5.css";
.e-tab-text {
    font-size: 0.78rem !important
}
.e-de-pane-disable-clr.e-de-ctnr-properties-pane-btn .e-btn {
    background-color: lightgray !important;
}
.e-de-pane-enable-clr.e-de-ctnr-properties-pane-btn .e-btn {
    background-color: lightgray !important;
}
.e-de-ctnr-showhide {
    color: black !important;
    font-weight: bolder
}
.e-de-prop-dropdown {
    font-size: 0.75rem !important
}
.e-de-status-bar button {
    font-size: 0.75rem !important
}
.e-de-pagenumber-input {
    font-size: 0.75rem !important
 }
.e-de-ctnr-pg-no-spellout {
    font-size: 0.75rem !important
}
.e-input .e-de-pagenumber-text {
    font-size: 0.75rem !important
}

.e-tbar-btn-text {
    font-size: 0.75rem !important
}
.e-documenteditorcontainer {
    width: 100% !important
}
.markerClass::marker{
    unicode-bidi: isolate;
    font-variant-numeric: tabular-nums;
    text-transform: none;
    text-indent: 0px !important;
    text-align: start !important;
    text-align-last: start !important;
}
/* .cke_contents {
    height: calc(100vh) !important;
} */
.hiddenIcon {
    color: transparent;
}
.make-sm{
    min-height: calc(1.5em + 0.5rem + 2px);
    padding: 0.25rem 0.5rem;
    font-size: 0.65625rem;
}
.edit-icon-right-aligned{
    position: absolute;
    right: 0;
    cursor: pointer;
    padding-right: 3px;
}
.edit-icon-right-aligned:hover{
    color: var(--bs-primary);
}

.span-right-aligned{
    position: absolute;
    right: 0;
    padding-right: 3px;
    z-index: 100;
}

.edit-save-icon{
    cursor: pointer;
}
.edit-save-icon:hover{
    color: var(--bs-success);
}
.edit-close-icon{
    cursor: pointer;
}
.edit-close-icon:hover{
    color: var(--bs-danger);
}


.document-editor {
    border: 1px solid var(--ck-color-base-border);
    border-radius: var(--ck-border-radius);

    /* Set vertical boundaries for the document editor. */
    max-height: 700px;

    /* This element is a flex container for easier rendering. */
    display: flex;
    flex-flow: column nowrap;
}

.document-editor__toolbar {
    /* Make sure the toolbar container is always above the editable. */
    z-index: 1;

    /* Create the illusion of the toolbar floating over the editable. */
    /*box-shadow: 0 0 5px hsla( 0,0%,0%,.2 );*/

    /* Use the CKEditor CSS variables to keep the UI consistent. */
    border-bottom: 1px solid var(--ck-color-toolbar-border);
}

/* Adjust the look of the toolbar inside the container. */
.document-editor__toolbar .ck-toolbar {
    border: 0;
    border-radius: 0;
}

.document-editor__editable-container {
    padding: calc( 2 * var(--ck-spacing-large) );
    background: var(--ck-color-base-foreground);

    /* Make it possible to scroll the "page" of the edited content. */
    overflow-y: scroll;
}

.document-editor__editable-container .ck-editor__editable {
    /* Set the dimensions of the "page". */
    width: 90%;
    min-height: 21cm;

    /* Keep the "page" off the boundaries of the container. */
    padding: 0.5cm 1cm 1cm;

    border: 1px hsl( 0,0%,82.7% ) solid;
    border-radius: var(--ck-border-radius);
    background: white;

    /* The "page" should cast a slight shadow (3D illusion). */
    box-shadow: 0 0 5px hsla( 0,0%,0%,.1 );

    /* Center the "page". */
    margin: 0 auto;
}

/* Adjust the headings dropdown to host some larger heading styles. */
.document-editor .ck-heading-dropdown .ck-list .ck-button__label {
    line-height: calc( 1.7 * var(--ck-line-height-base) * var(--ck-font-size-base) );
    min-width: 6em;
}

/* Scale down all heading previews because they are way too big to be presented in the UI.
Preserve the relative scale, though. */
.document-editor .ck-heading-dropdown .ck-list .ck-button:not(.ck-heading_paragraph) .ck-button__label {
    transform: scale(0.8);
    transform-origin: left;
}

/* Set the styles for "Heading 1". */
.document-editor .ck-content h2,
.document-editor .ck-heading-dropdown .ck-heading_heading1 .ck-button__label {
    font-size: 2.18em;
    font-weight: normal;
}

.document-editor .ck-content h2 {
    line-height: 1.37em;
    padding-top: .342em;
    margin-bottom: .142em;
}

/* Set the styles for "Heading 2". */
.document-editor .ck-content h3,
.document-editor .ck-heading-dropdown .ck-heading_heading2 .ck-button__label {
    font-size: 1.75em;
    font-weight: normal;
    color: hsl( 203, 100%, 50% );
}

.document-editor .ck-heading-dropdown .ck-heading_heading2.ck-on .ck-button__label {
    color: var(--ck-color-list-button-on-text);
}

/* Set the styles for "Heading 2". */
.document-editor .ck-content h3 {
    line-height: 1.86em;
    padding-top: .171em;
    margin-bottom: .357em;
}

/* Set the styles for "Heading 3". */
.document-editor .ck-content h4,
.document-editor .ck-heading-dropdown .ck-heading_heading3 .ck-button__label {
    font-size: 1.31em;
    font-weight: bold;
}

.document-editor .ck-content h4 {
    line-height: 1.24em;
    padding-top: .286em;
    margin-bottom: .952em;
}
.document-editor .cke-contents{
    height: 600vh !important;
}

/* Set the styles for "Paragraph". */
/*.document-editor .ck-content p {
    font-size: 1em;
    line-height: 1.63em;
    padding-top: .5em;
    margin-bottom: 1.13em;
}*/

/* Make the block quoted text serif with some additional spacing. */
.document-editor .ck-content blockquote {
    font-family: Georgia, serif;
    margin-left: calc( 2 * var(--ck-spacing-large) );
    margin-right: calc( 2 * var(--ck-spacing-large) );
}

.selected_style  {
    border: 2px black !important;
    color: #0275d8 !important;
    font-weight: bold !important;
}

/* Dropdown */
.documentMenu{
    margin-top: 0.2rem;
    border: 1px solid lightgrey;
}

.activeRevisionItem{
    color: var(--bs-primary);
    text-decoration: underline black !important;
    font-weight: 600;

}

.hoverRevisionItem{
    text-decoration: underline white;
    transition: all 0.3s linear;
}
.hoverRevisionItem:hover{
    cursor: pointer;
    color: dodgerblue;
    transition: all 0.15s linear;
}

.greyIcon{
    color: lightgrey;
    transition: all 0.3s linear;
}
.greyIcon:hover{
    color: grey;
    text-decoration: underline;
    cursor: pointer;
    transition: all 0.3s linear;
}

.noteComment{
    border-top: 1px solid white;
    border-bottom: 1px solid white;
    transition: all 0.3s linear;
}
.noteComment:hover{
    border-top: 1px solid lightgrey;
    border-bottom: 1px solid lightgrey;
    background: rgb(233, 237, 255);
    transition: all 0.15s linear;
}

.view-list-popover-class.popover{
    margin: 0 auto;
    padding: 0 auto;
    border: 1px solid rgb(120, 120, 120);
}

.listStyles{
    list-style-position: inside;
    margin: 0 auto;
    padding-left: 0;
}

.acceptedIcon>.fa-check{
    color:var(--bs-success)
}
.acceptedIcon>.fa-times{
    color:var(--bs-danger)
}
.handbook {
    padding-bottom: 10px;
}
.hideToolbar .cke_top {
    display: none !important
}
.ck.ck-balloon-panel {
        z-index: 1050 !important;
}
.ck-balloon-panel{z-index:9999 !important}

</style>