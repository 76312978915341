<template>
    <div>
        <b-overlay class="handbook-table-overlay" :show="isLoading">
            <template #default>
                <!-- <b-tabs class='' v-model="tab_index">
                    <b-tab class="" title="Level Documents" @click="selectedItem = ''" lazy>
                        <Documents v-if="!showEditor" @selectedDocument="selectedEvent"/>
                    </b-tab>
                    <b-tab class="" title="Process" @click="selectedItem = ''" lazy>
                        <Documents v-if="!showEditor" @selectedDocument="selectedEvent"/>
                    </b-tab>
                    <b-tab class="" title="Policy" @click="selectedItem = ''" lazy>
                        <Documents v-if="!showEditor" @selectedDocument="selectedEvent"/>
                    </b-tab>
                    <b-tab class="" title="Guidelines" @click="selectedItem = ''" lazy>
                        <Documents v-if="!showEditor" @selectedDocument="selectedEvent"/>
                    </b-tab>
                    <b-tab class="" title="Procedures" @click="selectedItem = ''" lazy>
                        <Documents v-if="!showEditor" @selectedDocument="selectedEvent"/>
                    </b-tab>
                    <template #tabs-end>
                        <b-button style="float: right">
                        </b-button>
                    </template>
                </b-tabs>   -->
                <Documents v-if="!showEditor" @selectedDocument="selectedEvent"/>
            </template>
            <template #overlay>
                <h5>Loading Document</h5>
            </template>
        </b-overlay>
        <DocumentEditor v-if="showEditor" :readOnly="readOnly" @updateReadOnly="updateReadOnly" @exitEditor="handleEditorExit"/>
    </div>
</template>

<script>
import { levelComputed, handbookMethods, handbookComputed, levelMethods, profileComputed, profileMethods } from "@/state/helpers";
import Documents from "@/components/process/documentsTableV2.vue"
import DocumentEditor from "@/components/process/editDocument.vue"
export default {
    props:{
        'urlParam':{
            required: false,
        }
    },
    data:() => ({
        showEditor: false,
        readOnly: false,
        isLoading: false,
        tab_index: false
    }),
    components:{
        Documents,
        DocumentEditor,
    },
    computed:{
        ...levelComputed,
        ...handbookComputed,
        ...profileComputed
    },
    methods:{
        ...handbookMethods,
        ...levelMethods,
        ...profileMethods,
        selectedEvent(payload){
            console.log(payload)
            this.readOnly = payload.read_only;
            this.isLoading = true;
            let id = payload.id.row ? payload.id.row : payload.id
            this.loadDocument(id)
            .then(()=>{
                this.isLoading = false;
                this.showEditor = true;
            })
            .catch(()=>{
                this.isLoading = false;
            })      
        },
        setDocumentFromParam(){
            this.readOnly = true;
            this.loadDocumentByToken(this.urlParam)
            .then(()=>{
                this.isLoading = false;
                this.showEditor = true;
            })
            .catch(()=>{
                this.isLoading = false;
            })
        },
        handleEditorExit(){
            //* if urlParam was used to view document, return to normal /process route (without param)
            if(this.urlParam == false){
                this.showEditor = false;
            }
            else{
                //* clear router param
                this.$router.push('/handbook')
            }
            
        },
        handleHome(){
            this.showEditor = false;
        },
        updateReadOnly() {
            this.readOnly = !this.readOnly;
        }
        
    },
    mounted(){
        if(this.selected_level.id) {
            this.manuallySetLastUsedLevelID(this.selected_level.id)
        }
        this.$eventHub.$on('homeButtonEvent', this.handleHome)
        if(this.urlParam != false){
            this.setDocumentFromParam();
        }
    },
    beforeDestroy(){
        //if(this.selected_level.id) {
            this.manuallySetLastUsedLevelID(-1)
        //}
        this.$eventHub.$off('homeButtonEvent');
    }
    
}
</script>

<style>

</style>